<template>
    <div style="width: 100%;height: 100%; overflow: hidden;">
        <div class="AboutContainer">
            <div class="AboutTitle">ΕΠΙΚΟΙΝΩΝΙΑ</div>
            <div>Πως μπορούμε να βοηθήσουμε?</div>
            <br>
            <br>
            <div class="infoCircles">
                <div class="text-center centerEle" style="text-wrap: wrap;">
                    <div><v-icon size="40px" color="white">mdi-email</v-icon></div>
                    <h2 style="margin-top: 10px;">Email</h2>
                    <br>
                    <span>apply@sse.gr</span>
                </div>
                <div class="text-center centerEle">
                    <div><v-icon size="40px" color="white">mdi-phone</v-icon></div>
                    <h2 style="margin-top: 10px;">Call Us</h2>
                    <br>
                    <span>+30 2108904000</span>
                </div>
                <div class="text-center centerEle">
                    <div><v-icon size="40px" color="white">mdi-map-marker</v-icon></div>
                    <h2 style="margin-top: 10px;">Τοποθεσία</h2>
                    <br>
                    <span>Δυρραχίου 16, Βάρη 166 72</span>
                </div>
            </div>
        </div>
        <div class="ma-4">
            <v-row>
                <NavBar></NavBar>
            </v-row>
            <div class="centered-container">
                <v-col cols="12">
                    <div class="ql-editor" v-html="about"></div>
                </v-col>

            </div>
        </div>
    </div>
</template>

<script>
import NavBar from "./NavBar.vue";
import axios from 'axios'
import 'quill/dist/quill.snow.css'
import router from "@/router/index.js";

export default {
    components: {
        NavBar
    },

    data() {
        return {
            tableHeaders: [
                { text: 'Όνομα', value: 'name' },
                { text: 'Τίτλος', value: 'title' },
                { text: 'Θέση', value: 'position' },
                { text: 'Οργανισμός', value: 'organization' },
            ],
            tableData: [
                { name: 'Ιωάννης Ανδρόνικος', title: 'Εκπαιδευτικός (ΜΑ Ιστορίας)', position: 'Βοηθός ερευνητής', organization: 'ΕΜΤΕΛΕΟΠ ΕΤEΜΕΛΕΟΠ / ΚΕΝΙ' },
                { name: 'Παναγιώτης Γέροντας', title: 'Υποπλοίαρχος ΠΝ – Υπηρεσία Ιστορίας Ναυτικού (ΜΑ Ιστορίας)', position: 'Βοηθός ερευνητής', organization: 'ΕΜΤΕΛΕΟΠ ΕΤEΜΕΛΕΟΠ / ΚΕΝΙ' },
                { name: 'Ανδρέας Καστάνης', title: 'Καθηγητής Στρατιωτικής Ιστορίας ΣΣΕ', position: 'Ερευνητής', organization: 'ΕΜΤΕΛΕΟΠ ΕΤEΜΕΛΕΟΠ / ΚΕΝΙ' },
                { name: 'Στέφανος Παπαγεωργίου', title: 'Ομότιμος καθηγητής', position: 'Διευθυντής', organization: 'ΕΜΤΕΛΕΟΠ ΕΤEΜΕΛΕΟΠ / ΚΕΝΙ' },
                {
                    name: 'Αναστάσιος Πολύχρονος', title: 'Υποστράτηγος, Δρ. Ιστορίας, διδάσκων Ειδικός Επιστήμονας στη Στρατιωτική Ιστορία στη ΣΣΕ', organization: 'ΕΜΤΕΛΕΟΠ ΕΤEΜΕΛΕΟΠ / ΚΕΝΙ', position: 'Ερευνητής',
                }
            ]
        }
    },
    computed: {
        formattedAbout() {
            const div = document.createElement('div');
            div.innerHTML = this.about;

            // Get the alignment from the Quill editor
            const alignment = this.$parent.$refs.myQuillEditor.quill.getFormat().align;

            // Set the style attribute with the correct alignment
            if (alignment === 'center') {
                div.querySelector('.ql-editor').setAttribute('style', 'text-align: center');
            } else if (alignment === 'right') {
                div.querySelector('.ql-editor').setAttribute('style', 'text-align: right');
            } else {
                div.querySelector('.ql-editor').setAttribute('style', 'text-align: left');
            }

            return div.innerHTML;
        },
    },
    mounted() {

    },
    methods: {
        loginRedirect() {
            router.push("/login");
        },
        registerRedirect() {
            router.push("/signup");
        },
        updatePerson1() {
            let data = {
                firstName: this.person.firstName,
                lastName: this.person.lastName,
                fatherName: this.person.fatherName,
                origin: this.person.origin,
                editor: this.person.editor,
                property: this.person.property,
                categories: this.selectedCategory,
                bio: this.person.bio,
                bibliography: this.person.bibliography,
            };
            axios
                .post(axios.defaults.baseURL + `/api/person/${1}`, data)
                .then(response => {
                    console.log(response.data);
                    this.snackbarMessage = "Person successfully updated!";
                    this.snackbarColor = "success";
                    this.snackbar = true;
                    setTimeout(() => {
                        this.$router.push("/list");
                    }, 2000);
                })
                .catch(error => {
                    console.log(error);

                });
        }
    }
};
</script>

<style scoped>

.centerEle{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 200px;
    padding-bottom: 100px;
}

.infoCircles {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 200px;
}

.infoCircles div div {
    border-radius: 100px;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 214, 156);
}

.centered-container {
    margin-top: 1% !important;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    margin: 0 auto;
}

.AboutContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;

}

.AboutTitle {
    /* font-size: 100px; */
    text-align: center;
    z-index: 1;
    font-size: 30px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-weight: 700;
    color: rgb(59, 59, 59);
}

.AboutButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 20px;
}

.AboutButtons button {
    color: white;
    font-size: 15px;
    font-weight: 300;
    padding: 23px 28px !important;
}

.backgroundContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.365), rgba(0, 0, 0, 0.365), rgba(0, 0, 0, 0.365), rgba(0, 0, 0, 1)), url('../assets/evelpidon.jpg');
    background-size: cover;
}

.title {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 1.2rem !important;
    text-align: center;
    margin-bottom: -1rem;
    margin-top: 0.1rem;
    text-shadow: 1px 1px #CCCCCC;
}

.box {
    border: 1px solid black;
    padding: 10px;
}

.title1 {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 3.0em !important;
    text-align: center;
    margin-bottom: 0.5em;
    text-shadow: 1px 1px #CCCCCC;
}

h1,
h2,
h3 {
    text-align: center;
}

h2,
h3 {
    margin-top: 1.5em;
}

ul {
    list-style: disc;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    text-align: left;
}

li {
    margin-top: 0.5em;
}

.subtitle {
    text-align: left;
    margin-top: 0;
    margin-bottom: 1.5em;
}

footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 5%;
    background-color: #000000 !important;
    color: #ffffff;
    text-align: right !important;
}

.even {
    background-color: #ffb7b7;
}

.odd {
    background-color: #d6f9a2;
}

@media (max-width: 767px) {
    .title {
        font-size: 2rem !important;
    }
}
</style>