<template>
  <div class="">
    <v-app-bar class="navTest" app height="90"
      :style="isHomePage ? 'box-shadow: 0px 2px 10px 0.5px rgba(0, 0, 0, 0.2);' : 'box-shadow: 0px 2px 10px 0.5px rgba(0, 0, 0, 0);'"
      dark>
      <v-toolbar-items>
        <v-img @click="redirectToHome" class="ma-2 pr-5" :src="require('../assets/SSE_logo.png')"
          transition="scale-transition" style="width: 65px; cursor: pointer;" contain />
        <div style="color: black;text-align: center;margin: auto;padding-left: 5px;font-weight: 600;">Διαχείριση
          Αιτήσεων
          ΣΣΕ</div>
      </v-toolbar-items>
      <v-spacer />

      <div class="hidden-lg-and-up">
        <v-menu bottom left style="margin-top: 20px;">
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon color="black">mdi-menu</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item v-if="isAdmin">
              <v-btn plain class="secondary--text" :ripple="false" text @click="forward('users')">ΧΡΗΣΤΕΣ</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn plain class="secondary--text" :ripple="false" text @click="forward('InvActiveList')">ΕΝΕΡΓΕΣ
                ΠΡΟΣΚΛΗΣΕΙΣ</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn plain class="secondary--text" :ripple="false" text @click="forward('InvUserList')">ΟΙ
                ΑΙΤΗΣΕΙΣ ΜΟΥ</v-btn>
            </v-list-item>
            <v-list-item v-if="isAdmin">
              <v-btn plain class="secondary--text" :ripple="false" text @click="forward('InvAllList')">ΟΛΕΣ ΟΙ
                ΠΡΟΣΚΛΗΣΕΙΣ</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn plain class="secondary--text" :ripple="false" text @click="forward('personaldata')">ΠΡΟΣΩΠΙΚΑ
                ΣΤΟΙΧΕΙΑ</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn plain class="secondary--text" :ripple="false" text
                @click="forward('InfoSite')">ΕΠΙΚΟΙΝΩΝΙΑ</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn plain class="secondary--text" :ripple="false" text @click="forward('logout')">ΑΠΟΣΥΝΔΕΣΗ</v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <v-toolbar-items class="hidden-md-and-down" v-if="loggedIn">

        <v-btn plain class="secondary--text" :ripple="false" text @click="forward('users')"
          v-if="isAdmin">ΧΡΗΣΤΕΣ</v-btn>
        <v-btn plain class="secondary--text" :ripple="false" text @click="forward('InvActiveList')">ΕΝΕΡΓΕΣ
          ΠΡΟΣΚΛΗΣΕΙΣ</v-btn>
        <v-btn plain class="secondary--text" :ripple="false" text @click="forward('InvUserList')">ΟΙ
          ΑΙΤΗΣΕΙΣ ΜΟΥ</v-btn>
        <v-btn plain class="secondary--text" :ripple="false" text @click="forward('InvAllList')" v-if="isAdmin">ΟΛΕΣ ΟΙ
          ΠΡΟΣΚΛΗΣΕΙΣ</v-btn>
        <!-- <v-btn plain class="secondary--text" :ripple="false" text @click="forward('personaldata')">ΠΡΟΣΩΠΙΚΑ
          ΣΤΟΙΧΕΙΑ</v-btn> -->
          <v-btn plain class="secondary--text" :ripple="false" text @click="forward('InfoSite')">ΕΠΙΚΟΙΝΩΝΙΑ</v-btn>
        <!-- <v-btn plain class="secondary--text" :ripple="false" text @click="forward('logout')">ΑΠΟΣΥΝΔΕΣΗ</v-btn> -->

        <v-menu bottom left :rounded="rounded"
        offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon color="grey">mdi-account</v-icon>
            </v-btn> 
          </template>

          <v-list>
            <v-list-item>
              <v-btn plain class="secondary--text" :ripple="false" text @click="forward('personaldata')">ΠΡΟΣΩΠΙΚΑ
                ΣΤΟΙΧΕΙΑ</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn plain class="secondary--text" :ripple="false" text @click="forward('logout')">ΑΠΟΣΥΝΔΕΣΗ</v-btn>
            </v-list-item>
          </v-list>
        </v-menu>

      </v-toolbar-items>

    </v-app-bar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "navbar",
  data: () => ({
    closeOnContentClick: false,
    selectedLang: null,
    adminOrGreek: false,
    navLinks: [],
    excelLinks: [],
    greekData: {},
    formLinks: [],
    dataLinks: [],
    loggedInMemberLinks: [],
    false: false,
    dialog: false,
    path: false,
    sideMenuLinks: []
  }),

  watch: {
    $route: {
      handler(to, from) {
        console.log(to, from);
      },
      immediate: true // This will trigger the watcher immediately upon component creation
    }
  },
  methods: {
    ...mapActions("user", ["destroyToken"]),

    redirectToHome() {
      this.$router.push("/").catch(() => { });
    },
    logout() {
      {

        this.destroyToken().then(() => {
          this.$router.push("/login").catch(() => { });
        });
      }
    },

    forward(pageToMove) {
      if (pageToMove === 'users') {
        this.$router.push("/users").catch(() => { });
      }
      else if (pageToMove === 'InvAllList') {
        this.$router.push("/InvAllList").catch(() => { });
      }
      else if (pageToMove === 'InvActiveList') {
        this.$router.push("/InvActiveList").catch(() => { });
      }
      else if (pageToMove === 'InvUserList') {
        this.$router.push("/InvUserList").catch(() => { });
      }
      else if (pageToMove === 'InfoSite') {
        this.$router.push("/infoSite").catch(() => { });
      }
      else if (pageToMove === 'logout') {
        this.destroyToken().then(() => {
          this.$router.push("/login").catch(() => { });
        });
      } else if (pageToMove === this.$t("login")) {
        this.$router.push("/login").catch(() => { });
      }
      else if (pageToMove === 'personaldata') {
        this.$router.push("/personaldata").catch(() => { });
      }
    },
  },
  computed: {
    ...mapGetters("user", ["getUserRole", "loggedIn"]),
    isHomePage() {
      return document.URL.includes("about");
    },
    isAdmin() {
      return this.getUserRole.includes('admin');
    }
  },
  beforeMount() {
    if (this.$i18n.locale == "en") {
      this.selectedLang = 0;
    } else {
      this.selectedLang = 1;
    }
    if (window.location.href.match("landingPage")) {
      this.path = true;
    }
    if (this.loggedIn) {

      this.navLinks = [
        { title: this.$t("users") }];
      if (this.isAdmin) {
        this.sideMenuLinks = [
          { title: this.$t("users") }, { title: this.$t("Invitations") }];
      }
      this.loggedInMemberLinks = [{ title: "Αποσυνδεση" }];
    }
  },
};
</script>

<style scoped>
.listItem {
  padding-left: 16px !important;
  margin: 0 16px !important;
}

.navTest {
  background: linear-gradient(0deg, rgb(255, 255, 255), rgb(239, 239, 239) 35%, rgb(225, 225, 225) 100%) !important;
}

.v-menu__content {
  background: white;
}

.apexcharts-toolbar {
  z-index: 0;
}

.v-btn--active .v-btn__content {
  color: whitesmoke;
}

.btnContainer {
  align-self: center;
}

.paddingLeft {
  padding-left: 1rem;
}

.listItem {
  margin: 1rem;
}
</style>
