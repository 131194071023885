import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/components/Login.vue";
import About from "@/components/About.vue";
import LandingPage from "../components/Views/LandingPage.vue";
import Users from "@/components/Users.vue";
import InvAllList from "@/components/InvAllList.vue";
import InvActiveList from "@/components/InvActiveList.vue";
import store from "@/state/store.js";
import ForgotPassword from "../components/ForgotPassword.vue";
import PasswordForm from "../components/PasswordForm.vue";
import Signup from "@/components/Signup.vue";
import MySubmission from "@/components/MySubmission.vue";
import ViewEditPerson from "../components/ViewEditPerson.vue";
import AccessDenied from "@/components/AccessDenied.vue";
import AddInv from "../components/AddInv.vue";
import EditInvitation from "../components/EditInvitation.vue";
import PersonalData from "../components/PersonalData.vue";
import InvUserList from "../components/InvUserList.vue"
import InfoSite from "../components/InfoSite.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/landingPage",
    component: LandingPage,
    name: "landingPage",
  },

  {
    path: "/accessDenied",
    component: AccessDenied,
    name: "accessDenied",
  },

  {
    path: "/forgot-password",
    component: ForgotPassword,
    name: "ForgotPassword",
  },

  {
    path: "/passwordForm",
    component: PasswordForm,
    name: "PasswordForm",
  },
  {
    path: "/infoSite",
    component: InfoSite,
    name: "InfoSite",
  },
  {
    path: "/login",
    component: Login,
    name: "login",
    beforeEnter: (to, from, next) => {
      const isAdmin = Array.isArray(store.getters["user/getUserRole"]) && store.getters["user/getUserRole"].includes('admin');
      const isLoggedIn = store.getters["user/loggedIn"];

      if (isLoggedIn && isAdmin) {
        next({ name: 'InvAllList' });
      } else if (isLoggedIn) {
        next({ name: 'personaldata' });
      } else {
        next();
      }
    }
  },
  {
    path: "/personaldata",
    component: PersonalData,
    name: "PersonalData",
    meta: { requiresAuth: true },
  },
  {
    path: '/',
    name: 'home',
    beforeEnter: (to, from, next) => {
      const isAdmin = store.getters["user/getUserRole"] == "admin"
      const isLoggedIn = store.getters["user/loggedIn"];

      if (isLoggedIn && isAdmin) {
        next({ name: 'InvAllList' });
      } else {
        next({ name: 'InvActiveList' });
      }
    }
  },
  {
    path: "/about",
    component: About,
    name: "about",
  },
  {
    path: "/users",
    component: Users,
    name: "users",
    meta: { requiresAuth: true, isAdmin: true },
  },
  {
    path: "/InvAllList",
    component: InvAllList,
    name: "InvAllList",
    meta: { requiresAuth: true, isAdmin: true, },
  },
  {
    path: "/InvUserList",
    component: InvUserList,
    name: "InvUserList",
    meta: { requiresAuth: true,  isAdmin: false,  },
  },
  {
    path: "/InvActiveList",
    component: InvActiveList,
    name: "InvActiveList",
    meta: { requiresAuth: true, isAdmin: false, },
  },
  {
    path: "/signup",
    component: Signup,
    name: "signup",
    meta: { requiresAuth: false },
    beforeEnter: (to, from, next) => {
      const isAdmin = Array.isArray(store.getters["user/getUserRole"]) && store.getters["user/getUserRole"].includes('admin');
      const isLoggedIn = store.getters["user/loggedIn"];

      if (isLoggedIn && isAdmin) {
        next({ name: 'list' });
      } else if (isLoggedIn) {
        next({ name: 'InvOpenList' });
      } else {
        next();
      }
    }
  },
  {
    path: "/MySubmission",
    component: MySubmission,
    name: "MySubmission",
    meta: { requiresAuth: true, isAdmin: false },
  },
  {
    path: "/viewEdit",
    component: ViewEditPerson,
    name: "ViewEditPerson",
    meta: { requiresAuth: true },
  },
  {
    path: "/addInv",
    component: AddInv,
    name: "addInv",
    meta: { requiresAuth: true, isAdmin: true, },
  },
  {
    path: "/EditInvitation",
    component: EditInvitation,
    name: "EditInvitation",
    meta: { requiresAuth: true, isAdmin: true, },
  },
  {
    path: '/personaldata/:id',
    component: PersonalData,
    name: 'Personal Data',
  },


];

const router = new VueRouter({
  mode: "history",
  routes,
  base: "/",
});
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAdminRoute = to.matched.some(record => record.meta.isAdmin);
  const isLoggedIn = store.getters["user/loggedIn"];
  const userRole = store.getters["user/getUserRole"];
  const isAdmin = userRole == "admin";

  if (requiresAuth && !isLoggedIn) {
    next({ path: "/login", query: { redirect: to.fullPath } });
  } else if (isAdminRoute && !isAdmin) {
    next({ name: 'accessDenied' });
  } else {
    next();
  }
});

export default router;
