import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/state/store";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router/index.js";

Vue.use(VueRouter);

export default {
    namespaced: true,
    state: {
        isLoggedIn: false,
        selectedInvitation: {
            jobRoles: []
        },
        selectedSubmission: {}
    },

    mutations: {
        setSelectedInvitationMutation(state, item) {
            state.selectedInvitation = item;
        },
        setSelectedSubmission(state, data) {
            state.selectedSubmission = data
        }
    },

    actions: {

        addUpdateSubmissionAction: async (_, submissionToBeUpdatedRequestBody) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + store.state.user.token;
            await axios.post("/submission/addEditSubmission", submissionToBeUpdatedRequestBody)
                .then(async ({ data, status }) => {
                    if (status === 200) {
                        Swal.fire({
                            icon: "success",
                            title: "Success!",
                            text: "Επιτυχής Υποβολή Αίτησης",
                            timer: 2000,
                            timerProgressBar: true,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                            showConfirmButton: false
                        });
                        store.state.submission.selectedSubmissionId = data.id
                    }
                })
                .catch(async (error) => {
                    Swal.fire({
                        icon: "info",
                        title: "Προσοχή!",
                        text: error.response.data.message,
                        timer: 2000,
                        timerProgressBar: true,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showConfirmButton: false
                    });
                });
                router.push("/InvActiveList");
        },

        getSubmissionByUserIdAndInvitationIdAction: async ({ commit }, querySubmission) => {
                axios.defaults.headers.common["Authorization"] = "Bearer " + store.state.user.token;
                const res = await axios.get("/submission/user/" + querySubmission.userId + "/invitation/" + querySubmission.invitationId)
                .then(({ data, status }) => {
                    if (status === 200) {
                        commit("setSelectedSubmission", data);
                    }
                    return data;
                })
                .catch((error) => {
                    console.log(error);
                    return null;
                });
                return res;
        },

        uploadFileAction: async (_, submission) => {                
                
                const formData = new FormData();
                formData.append("file", submission.file);
                formData.append("filename", submission.filename);
                formData.append("subid", submission.subid);
                formData.append("userName", submission.userName);
                formData.append("invName", submission.invName);
                axios
                    .post("/submission/uploadFile", formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(async ({ status }) => {
                        if (status === 200) {
                            console.log(status);
                            // filePayload.submission.underFileName = filePayload.underFile.name;
                            return submission.underFileName;
                        }
                    })
                    
        },

    },

    getters: {
        getSelectedInvitationJobRoles(state) {
            return state.selectedInvitation.jobRoles;
        },
        getSelectedSubmission(state) {
            return state.selectedSubmission;
        },
    }
}
